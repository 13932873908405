/*
 * BNYM brand fonts
 */
 @font-face {
  font-family: 'Lineto Akkurat';
  src: url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-light.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

 @font-face {
  font-family: 'Lineto Akkurat';
  src: url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-regular.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lineto Akkurat';
  src: url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-bold.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/lineto_akkurat-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Publico Banner Web Roman';
  src: url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-roman.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Publico Banner Web';
  src: url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-bold.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Publico Banner Web';
  src: url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-italic.woff2') format('woff2'),
      url('/app/shared-wl-assets/17/css/fonts/publico_banner_web-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Druk-Bold';
  src: url('/app/shared-wl-assets/17/css/fonts/druk-bold-web.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}
