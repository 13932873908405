@import './flexbox_layout';

@mixin panel($border-radius, $margin-bottom) {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),
                0 4px 5px 0 rgba(0,0,0,.14),
                0 1px 10px 0 rgba(0,0,0,.12);
    margin-bottom: $margin-bottom;
    border: none;
    -moz-border-radius: #{$border-radius}px;
    -webkit-border-radius: #{$border-radius}px;
    border-radius: #{$border-radius}px;
}

@mixin alert-panel($border-radius, $margin-bottom, $bg-color, $bd-color, $color, $padding) {
  @include nx-flex-container(row, nowrap, flex-start, center);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),
  0 4px 5px 0 rgba(0,0,0,.14),
  0 1px 10px 0 rgba(0,0,0,.12);
  color: $color;
  margin-bottom: $margin-bottom;
  background-color: $bg-color;
  border: 1px solid $bd-color;
  -moz-border-radius: #{$border-radius}px;
  -webkit-border-radius: #{$border-radius}px;
  border-radius: #{$border-radius}px;
  padding: $padding;
  line-height: 1.1;

  .icon {
    @include nx-flex-item(0, 1, auto);
    color: $color;
    font-size: 30px;
  }

  .text {
    @include nx-flex-item(0, 1, auto);
    color: $color;
    font-size: 16px;
    padding-left: 10px;
  }
}
