@use '@angular/material' as mat;
@import "../common_variables";
@import "../mixins/flexbox_layout";

@mixin nxn-card-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);
  $card-gradient-start: map-get($background, nxn-card-gradient-start);
  $card-gradient-end: map-get($background, nxn-card-gradient-end);

  // adding all dimensions as well, so the styling will get in the theming file
  .nxn-card {
    @include mat.elevation(3);
    @include nx-flex-container(column, nowrap, flex-start, stretch);
    background-color: map_get($background, nxn-card-within-tab);
    padding: $default-padding;
    height: 100%;
    border-radius: 2px;

    // can be used as column of the grid (has no box shadow around)
    &.no-elevation {
      @include mat.elevation(0);
      padding: 0;
    }

    // card with gradient
    &.gradient-bg {
      background: linear-gradient(180deg, #{$card-gradient-start} 0%, #{$card-gradient-end} 100%);
    }

    // card with top border
    &.colored-top-border {
      border-top: 5px solid map_get($accent, 500);
    }

    // gard with accent color background
    &.featured-card {
      background-color: map_get($accent, 800);
      color: mat.get-contrast-color-from-palette($accent, 800);

      a,
      .nx-icon {
        color: mat.get-contrast-color-from-palette($accent, 800);
      }
    }

    h2, h3, h4, h5, h6 {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .nxn-card-header {
      @include nx-flex-item(0, 1, auto); // not flexible
      margin-bottom: 20px;

      &.horizontal-layout {
        @include nx-flex-container(row, nowrap, flex-start, flex-start);

        .item {
          margin-right: $inner-margin;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      // Below h2 and h3 are set to have the same size because sometimes,
      // due to semantics, we need to use h2 or h3,
      // and we want the card heading to have consistent styling
      h3,
      h2, {
        margin: 0;
        line-height: 1.2;
        font-size: 20px;
        font-weight: 400;
      }

      img {
        max-width: 200px;
        height: auto;
        width: 100%;
      }
    }

    .nxn-card-content {
      @include nx-flex-item(1, 1, auto); // flexible - stretches vertically

      img {
        max-width: 100%;
        height: auto;
      }

      .img-max-width-250 {
        max-width: 250px;
      }

      .img-min-width-150 {
        min-width: 150px;
      }

      h3 {
        font-size: 18px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    // two column layout within card (this is class for container)
    // splits content to two columns
    .two-columns-layout {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
    }

    // two column layout
    .two-column-flex-container {
      // the container is needed to address the spacing for flexbox layout
      // solution: https://www.rawkblog.com/2019/01/flexbox-gutters-negative-margins-solved/
      overflow-x: hidden; // left and right only

      .two-column-flex-layout {
        @include nx-flex-container(row, wrap, flex-start, flex-start);
        margin-left: -5%;

        .flex-item {
          @include nx-flex-item(1, 1, 45%);
          margin: 0 0 0 5%;
        }

        .flex-item-35 {
          @include nx-flex-item(1, 1, 35%);
          margin: 0 0 0 5%;
        }

        .flex-item-55 {
          @include nx-flex-item(1, 1, 55%);
          margin: 0 0 0 5%;
        }
      }
    }


    .nxn-card-footer {
      @include nx-flex-item(0, 1, auto); // not flexible vertically
      margin-top: $inner-margin;

      &.space-between-layout {
        @include nx-flex-container(row, wrap, space-between, center);
      }

      button {
        margin-right: $inner-margin;
      }
    }
  }
}
