@import "../../common/mixins/flexbox_layout";
// Material Dialog styling

// Support modal custom styling - start
.portal-support-dialog-panel {
  height: auto;
  width: 80vw;

  // !important is used to override material inline styling
  // width is used for IE 11 (it does not honor the max-with)
  @media (min-width: 1200px) {
    width: 70vw !important;
  }

  @media (min-width: 1300px) {
    width: 60vw !important;
  }

  @media (min-width: 1400px) {
    width: 55vw !important;
  }
}

.portal-support-dialog-content {
  padding: 24px;

  @media (max-width: 767px), (max-height: 750px) {
    max-height: 65vh;
    overflow: auto;
    //margin: 0 -24px;
    //padding: 0 24px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #000;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb { // Handle
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
  }

  @media (min-width: 768px) and (min-height: 751px) {
    height: auto;
  }

  .portal-support  .phone-item {
    @include nx-flex-item(0, 1, 100%); // one columns
    margin-right: 0;

    @media (min-width: 500px) {
      @include nx-flex-item(0, 1, 49%); // two columns
      margin-right: 1%;
    }

    @media (min-width: 740px) {
      flex-basis: 32.33333%; // three columns
    }

    @media (min-width: 900px) {
      flex-basis: 24%;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .portal-support {
    .contact-email {
      &:first-child {
        display: inline-block;
      }
    }
    p .internal-support-details {
        display: block;
    }
  }
}
// ===== Support modal custom styling - end ======

// ======= Material dialog custom styling ========

// customized dialog outer panel
// add this class to the dialog config:
// panelClass: 'portal-dialog-panel'
// !important is used to override material inline styling
// width is used for IE 11 (it does not honor the max-with)
.portal-dialog-panel {
  height: auto;
  max-width: 80vw;

  @media (min-width: 768px) {
    width: 65vw !important;
  }

  @media (min-width: 992px) {
    width: 50vw !important;
  }
}

// Custom content container for material dialog
// removes the scroll from the content on large breakpoint
// NOTE: do not use it if scroll is needed for large breakpoint
.portal-dialog-content {
  padding: 24px;

  @media (max-width: 767px), (max-height: 750px) {
    max-height: 65vh;
    overflow: auto;
    //margin: 0 -24px;
    //padding: 0 24px;
  }

  @media (min-width: 768px) and (min-height: 751px) {
    height: auto;
  }
}

// custom button bar for material dialog
.portal-dialog-button-bar {
  @include nx-flex-container(row, wrap, flex-end, center);
  padding: 15px;

  button,
  .btn.btn-primary,
  .btn.btn-secondary {
    @include nx-flex-item(0, 1, auto);
    margin-left: 10px;
  }
}

// Dialog Theming
@mixin nxn-dialog-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  .portal-support-dialog-content {
    &::-webkit-scrollbar-track {
      background: map_get($background, nxn-scrollbar-track-bg);
    }

    &::-webkit-scrollbar-thumb { // Handle
      background-color: map_get($background, nxn-scrollbar-thumb-bg);
    }

    &::-webkit-scrollbar-thumb:hover { // Handle on hover
      background: map_get($background, nxn-scrollbar-thumb-hover-bg);
    }
  }
}
