// basic flex container
// https://tympanus.net/codrops/css_reference/flexbox/
@mixin nx-flex-container($flex-direction, $flex-wrap, $justify-content, $align-items) {
    -webkit-display: -webkit-box;
    -webkit-display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: $flex-direction; // row | row-reverse | column | column-reverse
    flex-wrap: $flex-wrap; // nowrap | wrap | wrap-reverse
    justify-content: $justify-content; // main axis: flex-start | flex-end | center | space-between | space-around
    align-items: $align-items; // across axis: flex-start | flex-end | center | baseline | stretch
}

// basic flex item
// flex: flex-grow | flex-shrink | flex-basis
// 1 1 auto - flexible so that the item absorb any free space along the main axis
// 0 1 auto - not flexible, the item does not take free space and keeps the width/height according to the content size

// flex-grow: number
// determines how much the flex item will grow relative to the rest of the flex items in the flex container when
// positive free space is distributed. The initial value is zero (0), and negative numbers are invalid.

// flex-shrink: number
// determines how much the flex item will shrink relative to the rest of the flex items in the flex container when
// negative free space is distributed. The flex shrink factor is multiplied by the flex basis when
// distributing negative space. The initial value is zero (1), meaning that the items don’t shrink by default,
// and negative numbers are invalid.

// flex-basis: auto | width
// sets the flex basis: the initial main size of the flex item, before free space is distributed according to
// the flex factors

@mixin nx-flex-item($flex-grow, $flex-shrink, $flex-basis) {
    -webkit-box-flex: $flex-grow;
    -webkit-flex: $flex-grow $flex-shrink $flex-basis;
    -ms-flex: $flex-grow $flex-shrink $flex-basis;
    flex: $flex-grow $flex-shrink $flex-basis;
}
