@import "./common_variables";

// Common styling

// for IE 11
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

.portal-page-heading-container {
  margin-bottom: $heading-bot-margin-lg;

  h1 {
    margin-bottom: 0;
  }
}

@mixin nxn-common-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // TODO: styling below is defined in theming because there is no file for portal yet
  section.nxn-section-with-list ul,
  ul.nxn-list {
    margin: -7px 0 $margin-bottom 0;
    list-style-type: disc;
    padding-left: 1.2em;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin: 7px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .nx-list-unstyled {
    &:last-child {
      margin-bottom: 0;
    }
  }

  section {
    margin-bottom: $margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // for cms table
  table.img-caption,
  table.blockquote {
    background-color: transparent;
  }

  table.img-caption {
    margin-bottom: $margin-bottom;
  }

}
