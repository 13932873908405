@import 'nx-components/nx-core/theming/theming';
@import 'nx-components/nx-core/theming/all-theme';
@import 'nx-components/nx-core/core';
@import './nxn-palette';
@import '../../apps/common/font/font_face_bnym';
@import '../../apps/common/theming/all-theme';
@import '../../apps/common/typography/typography';

@include nx-core(nx-typography-config($assetPath: '/app/shared-wl-assets/17/css/assets/', $styleHtmlTags: false));

// Light theme palettes
$gateway-accent: nx-palette($nx-accent);
$gateway-primary: nx-palette($nx-primary);
$gateway-warn: nx-palette($nx-warn);

// Dark theme palettes
$gateway-accent-dark: nx-palette($nx-accent-dark);
$gateway-primary-dark: nx-palette($nx-primary-dark);
$gateway-warn-dark: nx-palette($nx-warn-dark);

// Define Light theme
$gateway-light-theme: nx-light-theme(
    $gateway-primary,
    $gateway-accent,
    $gateway-warn,
    $nx-light-theme-foreground,
    $nx-light-theme-background);

// Define Dark theme
$gateway-dark-theme: nx-dark-theme(
    $gateway-primary-dark,
    $gateway-accent-dark,
    $gateway-warn-dark,
    $nx-dark-theme-foreground,
    $nx-dark-theme-background);

// Build Light Theme
@include nx-components-theme($gateway-light-theme); // nx-components
@include nxn-components-theme($gateway-light-theme); // components from this repo
@include nxn-components-typography($gateway-light-theme); // components from this repo

// Build Dark Theme
// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {
  @include nx-components-theme($gateway-dark-theme); // nx-components
  @include nxn-components-theme($gateway-dark-theme); // components from this repo
  @include nxn-components-typography($gateway-dark-theme); // components from this repo
}
