@import "../common_variables";
@import "../mixins/flexbox_layout";

// Button
.nx-icon-button  {
  cursor: pointer;
}

.portal-button-bar {
  @include nx-flex-container(column, nowrap, flex-start, center);
  margin-top: 24px;
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 24px;

  @media (min-width: 600px) {
    @include nx-flex-container(row, nowrap, flex-end, center);
    padding-bottom: 5px;
  }

  button,
  .item,
  .btn.btn-primary,
  .btn.btn-secondary {
    @include nx-flex-item(0, 1, auto);
    margin-left: 0;
    margin-bottom: 10px;

    @media (min-width: 600px) {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }

  button {
    @media (max-width: 599px) {
      min-width: 150px;
    }
  }

  .item-disclaimer {
    margin-right: 0;
    margin-left: 0;

    @media (min-width: 600px) {
      margin-right: auto;
    }

    // fix for IE 11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @media (min-width: 600px) {
        margin-right: 0;
      }
    }
  }
}

.close-button {
  flex: 0 1 auto;
  width: 42px;
  border: none;
  height: 42px;
  //margin: -12px -12px -12px 12px;
  padding: 0;
  -ms-flex: 0 1 auto;
  font-size: 32px;
  box-shadow: none;
  transition: background-color 100ms;
  font-weight: 100;
  line-height: 42px;
  -webkit-flex: 0 1 auto;
  border-radius: 100%;
  -webkit-box-flex: 0;

  cursor: pointer;
  display: inline-block;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  background-image: none;

  &:not(:active) {
    background-color: transparent;
  }

  &:hover {
    border-color: transparent;
    border: none;
  }

  &:focus,
  &:active {
    border-color: transparent;
    text-decoration: none;
  }

}

// Button Theming
@mixin nxn-button-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $color: map-get($theme, color);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  .portal-button-bar {
     border-top-color: map_get($foreground, dividers);
  }

  .close-button {
    color: #{map_get($foreground, icon)};

    &:hover {
      background-color: #{map_get($background, time-input-item-hover)};
      color: #{map_get($foreground, text)};
    }

    &:focus {
      background-color: #{map_get($background, time-input-item-hover)};
      color: #{map_get($foreground, text)};
    }
  }
}
