@import "../../common/mixins/panel";
@import "../../common/common_variables";
@import "./variables";

// NEXEN Login features content

.portal-login-features-container {
  @include panel(3, 24px);

  @media screen and (max-width: 767px) {
    display: none;
  }
}

// New Features Section
.nx-main {
  padding: $default-padding;

  @media (min-width: 992px) {
    padding: $default-padding 0;
  }

  @media (min-width: 1200px) {
    padding: 50px 0;
  }

  .page-heading {
    margin: 0 0 $margin-bottom 0;

    @media (min-width: 992px) {
      margin: 0 $default-margin $margin-bottom $default-margin;
    }

    @media (min-width: 1200px) {
      margin: 0 50px $margin-bottom 50px;
    }

    h1 {
      margin: 0 0 7px 0;
      font-size: 32px;
      font-family: "Times New Roman", Times, serif;
      font-style: italic;
      font-weight: normal;
    }

    .page-heading-underline {
      width: 150px;
      height: 5px;
    }
  }

  .article-type,
  .article-date {
    display: block;
    text-transform: uppercase;
    margin-bottom: $margin-bottom - 10;
  }

  .nx-btn-section {
    margin: 0;
  }

  .nx-section-link {
    cursor: pointer;
    display: inline-block;
  }

  // tweak columns and add border in between sections
  .nx-col-lg-4.nx-main-section {
    border-width: 0;
    border-style: solid;

    @media (max-width: 991px) {
      border-bottom-width: 1px;
      padding-bottom: $default-padding;
    }

    @media (min-width: 992px) {
      border-right-width: 1px;
      padding: 0 $default-padding;
    }

    @media (min-width: 1200px) {
      padding: 0 50px;
    }

    &:last-child {
      @media (max-width: 991px) {
        border-bottom-width: 0;
        padding-bottom: 0;
      }
      @media (min-width: 992px) {
        border-right-width: 0;
      }
    }
  }

  @media (min-width: 992px) {
    // IE 11 only
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .nx-col-lg-4.nx-main-section {
        margin: 0;
        width: 33.33333%; // calc(33.33333% - 24px)
      }
    }
  }

  .nx-grid {
    @media (min-width: 992px) {
      column-gap: 1px;
    }
  }

  // spacing for article
  .nx-flex-container-split,
  .nx-flex-container-split + h1,
  .article-body {
    margin: 0;

    @media (min-width: 992px) {
      margin: 0 $default-margin;
    }

    @media (min-width: 1200px) {
      margin: 0 50px;
    }
  }

  .nx-flex-container-split + h1 {
    margin-bottom: $default-margin;
  }

  .article-body {
    max-height: 350px;
    min-height: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px #000;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb { // Handle
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
  }

  .nxn-marketing-not-available {
    margin: 0;

    @media (min-width: 992px) {
      margin: 0 $default-margin;
    }

    @media (min-width: 1200px) {
      margin: 0 50px;
    }
  }
}

// Features Theming
@mixin nxn-features-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  .portal-login-features-container {
    background-color: map_get($background, nxn-page-main-panel-bg-color);
  }

  .nx-main {
    .page-heading {
      color: map_get($foreground, nxn-login-page-heading-color);

      .page-heading-underline {
        background-color: map_get($background, nxn-login-page-heading-underline);
      }
    }

    .article-type,
    .article-date {
      color: map_get($foreground, nxn-login-article-date-color);
    }

    .nx-col-lg-4.nx-main-section {
      border-color: map_get($foreground, dividers);
    }

    .article-body {
      &::-webkit-scrollbar-track {
        background: map_get($background, nxn-scrollbar-track-bg);
      }

      &::-webkit-scrollbar-thumb { // Handle
        background-color: map_get($background, nxn-scrollbar-thumb-bg);
      }

      &::-webkit-scrollbar-thumb:hover { // Handle on hover
        background: map_get($background, nxn-scrollbar-thumb-hover-bg);
      }
    }
  }
}
