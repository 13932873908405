// common variables

// margins
$default-margin: 24px;
$default-padding: 24px;
$margin-bottom: $default-margin;
$inner-margin: 15px;

// support
$support-inner-margin: $inner-margin;

// headings
$heading-bot-margin-lg: 20px;
$heading-bot-margin-md: 15px;
$heading-bot-margin-sm: 15px;
