// tagline styling. Currently not used
.tag-line {
  padding: 0;
  margin: 25px 25px 0 25px;

  @media (min-width: 768px) {
      margin: 40px 0 15px 0;
  }

  @media (min-width: 992px) {
      margin: 50px 0 15px 50px;
  }

  @media (min-width: 1200px) {
      margin: 70px 0 15px 100px;
  }

  @media (min-width: 1300px) {
      margin: 70px 0 15px 130px;
  }

  .logo {
    background-repeat: no-repeat;
    height: 52px; // image width: 52
    width: 227px; // image height: 227
    margin-bottom: 6px;
  }

  .logo-underline {
    height: 6px;
    width: 180px;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      margin-bottom: 10px;
    }
  }

  .slogan {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    color: rgba(0,0,0, .87);
    line-height: 1.1;
    font-size: 20px;
    // text-shadow: -1px -1px 1px rgba(255,255,255,.3), 1px 1px 1px rgba(0,0,0,.5);

    @media (min-width: 480px) {
      font-size: 24px;
    }

    @media (min-width: 992px) {
      font-size: 28px;
      // line-height: 1.5;
    }
  }
}

@mixin nxn-tagline-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $nx-tagline-image: map-get($background, nxn-footer-logo-img); // the tagline logo needs to be added to palette
  // logo used: /app/shared-wl-assets/17/img/whitelabel/nxn/logo_nxn_color.png

  .logo {
    background-image: url($nx-tagline-image);
  }

  .logo-underline {
    background-color: map_get($background, nxn-login-tagline-underline);
  }
}
