@import "./variables";
@import "../../common/mixins/flexbox_layout";
// login box styling

// login links
.login-box-links-container {
  @include nx-flex-container(row, wrap, flex-start, center);
  margin-bottom: $login-box-inner-margin;

  &.space-between {
    @include nx-flex-container(row, nowrap, space-between, center);
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  .login-box-link-item {
    @include nx-flex-item(0, 1, auto); // not flexible
    padding-right: 10px;
    font-size: $login-box-font-size;

    &.forgot-items {
      width: 100%;

      & a {
        display: inline-block;
        width: 50%;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &.with-separator .login-box-link-item {
    // this will work nicely only if items fit horizontally
    padding-right: 0;

    &:after {
      content: "|"; //  |
      padding: 0 7px;
      color: #a6a6a6;
    }

    &:last-child:after {
      content: "";
    }

    &.forgot-items:last-child:after {
      content: "";
      display: none;
    }
  }
}

.login-box-text-container {
  margin-bottom: $login-box-inner-margin;
  font-size: $login-box-font-size;
}
