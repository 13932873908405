@import "../components/article";
@import "../components/button";
@import "../components/card";
@import "../../nxn-login/nxn_scss/dialog";
@import "../../nxn-login/nxn_scss/features"; // TODO: remove and add directly to NXN
@import "../components/footer";
@import "../layout/layout";
@import "../components/list";
@import "../components/material";
@import "../common";

/* NEXEN Theming */
@mixin nxn-components-theme($theme) {
  @include nxn-article-theme($theme);
  @include nxn-button-theme($theme);
  @include nxn-card-theme($theme);
  @include nxn-common-theme($theme);
  @include nxn-dialog-theme($theme);
  @include nxn-features-theme($theme); // TODO: remove and add directly to NXN
  @include nxn-footer-logo-theme($theme);
  @include nxn-layout-theme($theme);
  @include nxn-list-theme($theme);
  @include nxn-material-components-theme($theme);
  // @include footer-theme($theme); // css variables test
}
