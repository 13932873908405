@import "../common_variables";

// passing $theme parameter in case if we want to pass font family from palette
@mixin nxn-components-typography($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  // temporarily commented out HTML and headings because they are defined in NXN-CSS right now
  //html {
  //  font-size: 100%; // In most browsers, this defaults to 16 pixels
  //}

  //body {
  //  font-family: Arial, Helvetica, sans-serif;
  //  line-height: 1.5;
  //  font-size: 0.875em; // 14px
  //  font-weight: 400;
  //}

  //h1, h2, h3, h4, h5, h6 {
  //  margin: 0;
  //}

  //h1 {
  //  font-size: 24px;
  //  line-height: 1.1;
  //  font-weight: 400;
  //  margin-bottom: $heading-bot-margin-lg;
  //}
  //
  //h2 {
  //  font-size: 22px; // 20px
  //  line-height: 1.1;
  //  font-weight: 500;
  //  margin-bottom: $heading-bot-margin-lg;
  //}
  //
  //h3 {
  //  font-size: 20px; // 18px
  //  line-height: 1.2;
  //  font-weight: 400;
  //  margin-bottom: $heading-bot-margin-md;
  //}
  //
  //h4 {
  //  font-size: 18px; // 15px
  //  line-height: 1.2;
  //  font-weight: 700;
  //  margin-bottom: $heading-bot-margin-md;
  //}
  //
  //h5 {
  //  font-size: 15px;
  //  line-height: 1.2;
  //  font-weight: 500;
  //  margin-bottom: $heading-bot-margin-sm;
  //}
  //
  //h6 {
  //  font-size: 14px;
  //  line-height: 1.2;
  //  font-weight: 700;
  //  margin-bottom: $heading-bot-margin-sm;
  //}

  //p {
  //  margin-bottom: $margin-bottom;
  //
  //  &:last-child {
  //    margin-bottom: 0;
  //  }
  //}

  a.disabled {
    color: map_get($foreground, disabled);
    text-decoration: none;
    cursor: default;
  }

}
