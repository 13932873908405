@import "../common_variables";
@import "../mixins/layout";
@import "../mixins/panel";
@import "../mixins/flexbox_layout";

// main portal panel (one column)
.portal-content-panel {
  @include panel(3, $margin-bottom);
  padding: $default-padding;
  min-height: 350px;
  @media (min-width: 992px) {
    min-height: 450px;
  }
  @media (min-width: 1200px) {
    min-height: 550px;
  }
}

// Layout: Two panels Main and Aside
@media (min-width: 992px) {
  .nx-grid-no-gap {
    column-gap: 0 !important;
    grid-column-gap: 0 !important;
  }
}

/* IE 11 only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @media (min-width: 992px) {
    .nx-grid-container {
      padding-bottom: $margin-bottom + 12;
    }

    .portal-panel-main {
      margin-right: 0 !important; // override styling from theming.css
      width: calc(66.66667% - 12px);
    }
    .portal-panel-aside {
      margin-left: 0 !important; // override styling from theming.css
      width: calc(33.33333% - 12px);
    }
  }
}

.portal-panel-main,
.portal-panel-aside {
  @include panel(3, 0);
  padding: $default-padding;
}

// vertical layout
.vertical-split-layout-container {
  @include nx-flex-container(column, nowrap, space-between, stretch);
  height: 100%;
}

.vertical-layout-full-height-container {
  @include nx-flex-container(column, nowrap, flex-start, stretch);
  height: 100%;
}

// Layout Theming
@mixin nxn-layout-theme($theme) {
  $color: map-get($theme, color);
  $primary: map-get($color, primary);
  $accent: map-get($color, accent);
  $warn: map-get($color, warn);
  $background: map-get($color, background);
  $foreground: map-get($color, foreground);

  .portal-content-panel {
    background-color: map_get($background, nxn-page-main-panel-bg-color);
  }

  .portal-panel-main {
    background-color: map_get($background, nxn-page-main-panel-bg-color);
  }

  .portal-panel-aside {
    background-color: map_get($background, nxn-page-side-panel-bg-color);
  }

  // adding this class until nxn-wl-assets will replace nxn-css
  // for now this will be included in theming file
  .nxn-page-container {
    max-width: 1260px;
  }

  // Preamble: Combination of image and text block at the top of the page
  // Usually placed within the card in combination with nx-flex-container
  /* Usage:
    <div class="nx-flex-container preamble">
      <div class="nx-flex-item image-container">
          <img alt="Article Image" class="img-responsive-max-width-200" src="globe.png">
      </div>
      <div class="nx-flex-item text-container">
          <h1>Heading</h1>
          <p>Content</p>
      </div>
  </div>
  */
  .preamble {
    align-items: flex-start;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .image-container {
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }

      @media (min-width: 768px) {
        margin-right: 24px;
      }
    }

    .text-container {
      @media (min-width: 992px) {
        max-width: 600px;
      }
    }
  }

  .img-responsive-max-width-350 {
    display: block;
    height: auto;
    max-width: 350px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 200px;
    }
  }

  .img-responsive-max-width-200 {
    display: block;
    height: auto;
    max-width: 200px;
    width: 100%;
  }

  // container for dynamic content
  .section-dynamic-content {
    margin: 0;
    padding: 0;
  }

}

// ======== flexbox classes ==========

// Basic flex container with flex-start item alignment(left ot top)
.nx-flex-container {
  // layout horizontal aligned to left or top, centered vertically
  @include nx-flex-container(row, nowrap, flex-start, center);
}

// Add this class to container if you need flex-end item alignment (right or bottom)
.nx-flex-container-justify-right {
  justify-content: flex-end;
}

// Use this container class if you need vertical layout
.nx-flex-container-vertical {
  @include nx-flex-container(column, nowrap, flex-start, flex-start);
}

// Basic Item within container
// no grow | shrink only to its own size | base is auto (based on the item content)
.nx-flex-item {
  @include nx-flex-item(0, 1, auto); // not flexible
}

// grow absorb any free space | shrink only to its own size | base is auto (based on the item content)
.nx-flex-item-flexible {
  @include nx-flex-item(1, 1, auto); //  flexible so that the item absorb any free space along the main axis
}

// Container for aligning two items to opposite sides:
// One to the left and the other to the right (or top or bottom)
// the items also centered vertically
.nx-flex-container-split {
  @include nx-flex-container(row, nowrap, space-between, center);
}

// =========== Form Layout  =========================
// Responsive flex section - very useful for forms
// items will wrap in case if they are not fit within section (based on flex-basis 250px)

.nx-flex-section {
  @include nx-flex-container(row, wrap, flex-start, center);
  margin: -0.5rem; // balances out the  spacing
}

.nx-flex-section-item {
  //  item is flexible so that the item absorb any free space along the main axis
  // has default flex-basis 250px (the item will not shrink to less then 250 px and will wrap if the width exceeds)
  @include nx-flex-item(1, 1, 250px);
  margin: 0.5rem;
}

.nx-flex-section-item-not-flexible {
  // not flexible, the item does not take free space and keeps
  // the width/height according to the content
  // has default flex-basis 250px (the item will not shrink to less then 250 px and will wrap if the width exceeds)
  @include nx-flex-item(0, 1, 250px);
  margin: 0.5rem;
}

// ======== flexbox classes end ==========
